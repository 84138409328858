<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-data-table
      :headers="headers"
      :items="syncOrderLogs"
      :loading="isLoadingData"
      :server-items-length="syncOrderLogsMeta.totalItems"
      :options.sync="options"
      :items-per-page="30"
      :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
    >
      <template v-slot:item.hub="{ item }">
        {{ JSON.parse(item.hub).name }}
      </template>
      <template v-slot:item.error_message="{ item }">
        {{ item.error_message }}
      </template>
      <template v-slot:item.finished="{ item }">
        {{ item.finished ? "YES" : "NO" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip
          bottom
          v-if="
            ['create', 'update', 'createorupdate'].includes(
              item.action.toLowerCase()
            )
          "
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-if="syncInProgress && syncItem === item">
              <v-progress-circular
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
            </div>
            <div v-else>
              <v-icon
                small
                class="mr-2"
                v-bind="attrs"
                v-on="on"
                @click="sync(item)"
                color="primary"
                :disabled="syncInProgress"
              >
                mdi-sync
              </v-icon>
            </div>
          </template>
          <span>Sync order</span>
        </v-tooltip>
      </template>

      <template v-slot:no-data>
        {{ $t("no_data_available") }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>{{ $t("syncOrderLogs") }}</v-toolbar-title>
          <v-divider
            class="mx-2"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-row>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="options.search"
              :label="$t('search')"
              class=""
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-autocomplete
              clearable
              v-model="options.hub_id"
              :items="hubs"
              item-text="name"
              item-value="id"
              :label="$t('hub')"
              :search-input.sync="search_hub"
              :loading="is_loading_hubs"
              no-filter
            ></v-autocomplete>
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <v-menu
              v-model="menu_date_start"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.start_date"
                  :label="$t('start_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.start_date"
                no-title
                color="green lighten-1"
                header-color="green lighten-1"
                @input="menu_date_start = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <v-menu
              v-model="menu_date_end"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="options.end_date"
                  :label="$t('end_date')"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.end_date"
                no-title
                color="premary"
                @input="menu_date_end = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            sm="2"
          >
            <v-btn
              block
              color="primary"
              @click="filter"
              :loading="isLoadingData"
              :disabled="isLoadingData"
            >
              <v-icon
                color="white"
                class="mr-2"
              >
                mdi-filter
              </v-icon>
              {{ $t("filter") }}
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";

export default {
  components: {
    // ShowCheckout: () => import('./dialogs/ShowCheckout.vue'),
  },

  computed: {
    ...mapGetters({
      isLoadingData: "syncOrderLogs/isLoadingData",
      isVisibleShowDialog: "syncOrderLogs/isVisibleShowDialog",
      syncOrderLogs: "syncOrderLogs/list",
      syncOrderLogsMeta: "syncOrderLogs/meta",

      hubs: "hubs/activeHubs",
    }),
  },

  watch: {
    search_hub: debounce(function (search) {
      if (this.isLoadingHubs) return;

      this.$store.dispatch("hubs/list", {
        store_id: this.store_id,
        itemsPerPage: 30,
        search,
      });
    }, 1000),
  },

  data() {
    return {
      search_hub: "",
      is_loading_hubs: false,
      is_loading_syncOrderLogs: false,
      options: {},
      index: 0,

      menu_date_start: false,
      menu_date_end: false,
      syncInProgress: false,
      syncItem: null,

      headers: [
        {
          text: "id",
          align: "start",
          value: "loggable_id",
        },

        {
          text: this.$t("finished"),
          value: "finished",
          sortable: false,
        },
        {
          text: this.$t("hub"),
          value: "hub",
          sortable: false,
        },
        {
          text: this.$t("created_at"),
          value: "created_at",
        },
        {
          text: this.$t("error_message"),
          value: "error_message",
        },

        {
          text: this.$t("actions"),
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
    };
  },

  methods: {
    async sync(item) {
      this.syncInProgress = true;
      this.syncItem = item;
      try {
        await this.$store.dispatch("syncOrderLogs/syncOrder", item);
        this.$store.dispatch("alerts/success", "success");
      } catch (error) {
        this.$store.dispatch("alerts/error", error.message);
      } finally {
        this.syncInProgress = false;
        this.syncItem = null;
      }
    },

    async filter() {
      await this.$store.dispatch("syncOrderLogs/list", this.options);
    },
  },
};
</script>
